<template>
  <div class="lbasic_info">
    <el-form label-width="120px">
      <div v-for="(item,index) of datas"
           :key="index"
           class="lcontainer">
        <div class="ltitle">
          <span class="line"></span>
          <span>{{item.title}}</span>
          <span class="line"></span>
        </div>
        <div class="litems">
          <div v-for="item2 of item.items"
               :key="item2.name">
            <el-form-item :label="item2.title">
              <el-input :placeholder="`请输入${item2.title}`"
                        v-model="item2.value"
                        :disabled="!isManager"></el-input>
            </el-form-item>
          </div>
        </div>
      </div>
      <el-form-item style="text-align: center;">
        <el-button type="primary"
                   style="width:200px"
                   v-if="isManager"
                   @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'lbasicInfo',
  props: {
    uid: {
      type: String,
      default: () => { return '' }
    }
  },
  data () {
    return {
      datas: [],
      isManager: false
    };
  },
  watch: {
    uid: {
      handler () {
        this.getData()
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.isManager = this.common.cIsManager()
  },
  mounted () {
    this.ws.addCallback('xyBaseInfoAck', (res) => {
      this.datas = res.groups
    })
    this.ws.addCallback('setXyBaseInfoAck', (res) => {
      if (this.common.cGetResult(res)) {
        this.common.cToast(this, "保存成功", "success");
      } else {
        this.common.cToast(this, "保存失败", "error");
      }
    })
  },
  methods: {
    getData () {
      let json = {
        "cmd": "xyBaseInfo",
        "uid": this.uid
      }
      this.ws.send(json);
    },
    onSubmit () {
      let json = {
        "cmd": "setXyBaseInfo",
        "uid": this.uid
      }
      this.datas.forEach(item => {
        item.items.forEach(data => {
          json[data.name] = data.value
        })
      })
      this.ws.send(json);
    }
  }
}
</script>
<style lang="scss">
.lbasic_info {
  .lcontainer {
    margin-bottom: 50px;

    .ltitle {
      font-size: 20px;
      text-align: center;
      margin: 10px 0 25px 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .line {
        display: inline-block;
        width: 200px;
        height: 2px;
        background-color: #dddddd;
        margin: 0 50px;
      }
    }

    .litems {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
    }
  }

  .el-form-item__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

